import api from '../library/axios';

export default {
  getList({ workspace_id }) {
    return api({
      method: 'get',
      url: `/api/ai/threads/${workspace_id}`,
    });
  },
  getDetail({ thread_id, workspace_id }) {
    return api({
      method: 'get',
      url: `/api/ai/threads/${workspace_id}/detail/${thread_id}`,
    });
  },
  getSearch({ workspace_id, keyword }) {
    return api({
      method: 'get',
      url: `/api/ai/threads/${workspace_id}/search`,
      params: {
        keyword,
      },
    });
  },
  setSync({ workspace_id }) {
    return api({
      method: 'get',
      url: `/api/ai/threads/${workspace_id}/publish`,
    });
  },
  update({ thread_id, workspace_id, data }) {
    return api({
      method: 'patch',
      url: `/api/ai/threads/${workspace_id}/save/${thread_id}`,
      data,
    });
  },
};
